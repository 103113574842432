// hexをrgbに変換する
const hex2rgb = (hex: string) => {
  if (!hex) return hex;
  // 「#」はいらないので削除、rgbだった場合はそのまま表示
  if (hex.slice(0, 1) === '#') {
    hex = hex.slice(1);
    return `${parseInt(hex.slice(0, 2), 16)},${parseInt(hex.slice(2, 4), 16)},${parseInt(hex.slice(4, 6), 16)}`;
  }
  return hex;
};

const style = () => {
  const styles: StylesData = {
    fontFamily: window.config.setting.font_family,
    textColor: hex2rgb(window.config.setting.text_color),
    pointColor: hex2rgb(window.config.setting.point_color),
    baseColor: hex2rgb(window.config.setting.base_color),
    primaryColor: hex2rgb(window.config.setting.primary_color),
    secondaryColor: hex2rgb(window.config.setting.secondary_color),
    headerColor: hex2rgb(window.config.setting.header_color),
    headerMenuBgColor: hex2rgb(window.config.setting.header_menu_bg_color),
    headerMenuTextColor: hex2rgb(window.config.setting.header_menu_text_color),
    buttonBaseColor: hex2rgb(window.config.setting.button_base_color),
    buttonBaseTextColor: hex2rgb(window.config.setting.button_base_text_color),
    buttonPrimaryColor: hex2rgb(window.config.setting.button_primary_color),
    buttonPrimaryTextColor: hex2rgb(window.config.setting.button_primary_text_color),
    buttonSecondaryColor: hex2rgb(window.config.setting.button_secondary_color),
    buttonSecondaryTextColor: hex2rgb(window.config.setting.button_secondary_text_color),
    mypageIconColor: hex2rgb(window.config.setting.mypage_icon_color),
    topMainBannerBgColor: hex2rgb(window.config.top.main_banner_bg_color),
    topSubBannerBgColor: hex2rgb(window.config.top.sub_banner_bg_color),
    topMiniBannerBgColor: hex2rgb(window.config.top.mini_banner_bg_color),
    topTopicsBgColor: hex2rgb(window.config.top.topics_bg_color),
    topNewsBgColor: hex2rgb(window.config.top.news_bg_color),
    topGalleryBgColor: hex2rgb(window.config.top.gallery_bg_color),
    topMovieBgColor: hex2rgb(window.config.top.movie_bg_color),
    topGoodsBgColor: hex2rgb(window.config.top.goods_bg_color),
    topWysiwygBgColor: hex2rgb(window.config.top.wysiwyg_bg_color),
    topTalkroomBgColor: hex2rgb(window.config.top.talkroom_bg_color),
    topDiscographyBgColor: hex2rgb(window.config.top.discography_bg_color),
    topScheduleBgColor: hex2rgb(window.config.top.schedule_bg_color),
  };

  // ダークモードだったらbodyにdark-modeのクラスをつける
  if (window.config.setting.is_dark_mode) {
    document.body.classList.add('dark-mode');
  }

  // cssの変数を宣言し、値にデータをセット
  Object.keys(styles).forEach((key) => {
    if (styles[key]) {
      document.body.style.setProperty(`--${key}`, styles[key]);
    }
  });
};

export default style;
