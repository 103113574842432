













import { defineComponent, PropType, reactive } from '@vue/composition-api';

export default defineComponent({
  name: 'Modal',
  props: {
    modalStore: {
      type: Object as PropType<ModalStore>,
      default: {
        isActive: false,
      },
    },
  },
  setup(props) {
    const sharingStore = reactive(props.modalStore);
    const close = () => (sharingStore.isActive = false);
    return {
      sharingStore,
      close,
    };
  },
});
