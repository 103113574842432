































































































































































































































































































































































import { defineComponent, ref, PropType, computed, Ref, watch, onMounted } from '@vue/composition-api';
import FcModal from '@/views/purchase/components/Modal.vue';
import { download } from '@/admin/file';
import { useNotification } from '@/composition/notification';
import { usePurchasePreview } from '@/views/purchase/composition/purchasePreview';
import core from '@/admin/core';
import { DisplayDate } from '@/admin/util';
import style from '@/scripts/utility/Styles';

export default defineComponent({
  name: 'PurchasePreview',
  components: {
    FcModal,
  },
  props: {
    type: {
      type: String as PropType<'history' | 'payment' | 'purchase'>,
      default: 'payment',
    },
    productId: {
      type: String,
    },
  },
  setup(props) {
    const notification = useNotification();
    const purchasePreview = usePurchasePreview(props.productId);
    const product = purchasePreview.getStorageValue(props.productId);
    const mainThumbnail = ref(''); // メイン画像サムネイルURL取得用
    const subThumbnail = ref([]); // 追加画像サムネイルURL取得用
    // サムネイルと追加画像の画像パスの配列
    const displayImagesAll = computed(() => [mainThumbnail.value, ...subThumbnail.value]);
    const selectedImageIndex = ref(0); // 現在選択中のメイン画像の番号
    const displayImagesCountPerPage = 10; // 1ページに表示する画像の数
    const displayImagesPerPage = ref(displayImagesAll.value.slice(0, displayImagesCountPerPage)); // ページごとの表示する画像
    const currentPage = ref(1); // 現在のページ
    const totalPage = computed(() => Math.ceil(displayImagesAll.value.length / displayImagesCountPerPage)); // トータルページ数
    const purchaseQuantity = ref('1'); // 購入数
    const totalPrice = computed(() => product.item.price * Number(purchaseQuantity.value));
    const maxSalesQuantityPerUser = computed(() => product.item.maxSalesQuantityPerUser || 10);
    const isSoldOut = product.item.stockSettings
      ? product.item.stockSettings.salesQuantity >= product.item.stockSettings.maxSalesQuantity
      : false; // 売り切れフラグ
    const purchaseDate = new DisplayDate(new Date().getTime()).format('YYYY-MM-DD hh:mm'); // 購入日(プレビューは購入後の画面を直接確認するため確認日時にしておく)
    const expirationDate: Ref<Date> = ref(new Date()); // 有効期限
    const isExpired = computed(() => (product.isExpiration ? new Date() > expirationDate.value : false)); // 有効期限を過ぎているかのフラグ
    // 購入期間内であるかのフラグ
    const isExtraPurchaseDate = computed(() => {
      if (
        new Date().getTime() < product.item.purchaseStartDate.value ||
        (product.item.purchaseEndDate.value && new Date().getTime() > product.item.purchaseEndDate.value)
      ) {
        return true;
      } else {
        return false;
      }
    });
    // 有効期限
    const calculateExpirationDate = computed(() => {
      if (product.isExpiration) {
        expirationDate.value =
          product.expirationProperty.expirationType === 'date'
            ? new Date(product.expirationProperty.expirationDate.value)
            : purchasePreview.calculationInterval(product, expirationDate.value);
      }
      return expirationDate.value ? new DisplayDate(expirationDate.value.getTime()).format('YYYY-MM-DD hh:mm') : '';
    });
    const isBasic = ref(false);

    // モーダルの設定
    const modalStore = ref({
      isActive: false,
    });

    const blank = (isBlank?: boolean) =>
      isBlank
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {};

    const getDisplayImagesIndex = (index: number) => {
      return index + (currentPage.value - 1) * displayImagesCountPerPage;
    };

    const open = () => {
      modalStore.value.isActive = true;
    };

    // fc-purchase-item__add-imageの画像をクリックしたらselectedImageIndexの値を更新
    const onClick = (index: number) => {
      selectedImageIndex.value = index;
    };

    const updateDisplayImagesPerPage = (page: number) => {
      const offset = displayImagesCountPerPage * (page - 1);
      displayImagesPerPage.value = displayImagesAll.value.slice(offset, offset + displayImagesCountPerPage);
    };

    // ページ送りロジック
    const nextPage = () => {
      if (currentPage.value === totalPage.value) {
        return;
      }
      currentPage.value++;
      updateDisplayImagesPerPage(currentPage.value);
    };
    const prevPage = () => {
      if (currentPage.value === 1) {
        return;
      }
      currentPage.value--;
      updateDisplayImagesPerPage(currentPage.value);
    };

    const purchase = () => {
      props.type = 'purchase';
    };

    /**
     * サムネイル画像のダウンロード
     */
    const downloadThumbnail = async () => {
      if (product.item.thumbnail) {
        await download(product.item.thumbnail).then((signedUrl: string) => {
          mainThumbnail.value = signedUrl;
        });
      }

      if (product.item.images) {
        for (const i of product.item.images) {
          await download(i).then((signedUrl: string) => {
            (subThumbnail.value as string[]).push(signedUrl);
          });
        }
      }
    };

    /**
     * 購入期間外の表記を取得する
     * 購入期間外の条件下で未来日かそうではないかで判定
     *
     * @returns 購入開始日時と購入終了日時の表記
     */
    const extraPurchaseDateString = computed(() => {
      if (new Date().getTime() < product.item.purchaseStartDate.value) {
        return '販売開始前です。今しばらくお待ちください。';
      } else {
        return '販売期間が終了しました。';
      }
    });

    /**
     * 在庫表記文言を取得する
     *
     * @returns 在庫表記文言
     */
    const maxSalesString = () => {
      if (product.item.stockSettings) {
        // 在庫がない場合
        if (isSoldOut) return 'なし';
        // 在庫数を表示する
        else if (
          product.item.stockSettings.isDisplayRemainingQuantity &&
          product.item.stockSettings.thresholdOfRemainingQuantity >=
            product.item.stockSettings.maxSalesQuantity - product.item.stockSettings.salesQuantity
        ) {
          return `残り${product.item.stockSettings.maxSalesQuantity - product.item.stockSettings.salesQuantity}個`;
        } else if (
          product.item.stockSettings.thresholdOfRemainingQuantity >=
          product.item.stockSettings.maxSalesQuantity - product.item.stockSettings.salesQuantity
        ) {
          return '残りわずか';
        } else {
          return 'あり';
        }
      }
    };

    const init = async () => {
      // Basic認証で読み込んだjsファイルの内容からCSSで使用する変数を取得
      style();

      if (!product.isAccessEnd) {
        // 閲覧終了日をOFFにしても日付データは保持したままになり、プレビューは保存せずにできるためundefinedにする
        product.item.accessEndDate.value = undefined;
      }

      if (!product.isPurchaseEnd) {
        // 購入終了日をOFFにしても日付データは保持したままになり、プレビューは保存せずにできるためundefinedにする
        product.item.purchaseEndDate.value = undefined;
      }

      if (
        props.type === 'payment' &&
        (new Date().getTime() < product.item.accessStartDate.value ||
          (product.item.accessEndDate.value && new Date().getTime() > product.item.accessEndDate.value))
      ) {
        notification.error('閲覧期限外のため、ユーザー画面には表示されません。');
      }

      if (window.config.default) {
        notification.error('Basic認証が解除されていないため、デフォルトのカラー・フォントが使用されています');
      }

      // エンドユーザー画面に合わせたフォント設定にする(別タブ表示なのでフォントサイズのリセットはしない)
      document.documentElement.style.setProperty('font-size', '62.5%');
      // 通知バーのサイズが小さくなるため、少し大きめに戻す
      const snackBarContent = document.getElementsByClassName('v-snack__content') as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < snackBarContent.length; i++) {
        snackBarContent[i].style.fontSize = '1.3rem';
      }
      const snackBarAction = document.getElementsByClassName('v-size--default') as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < snackBarAction.length; i++) {
        snackBarAction[i].style.fontSize = '1.3rem';
      }

      try {
        downloadThumbnail();
      } catch (e) {
        notification.error(e);
      } finally {
        isBasic.value = true;
      }
    };

    onMounted(() => {
      const script = document.createElement('script');
      script.src = `${process.env.VUE_APP_HOSTING_URL}/config.js`;
      script.onload = init;
      // Basic認証を解除できなかった場合もデフォルトの設定でプレビューできるようにする
      script.onerror = init;
      // Unit04の設定値が取得できた場合、config.jsを上書きで読み込む
      document.head.appendChild(script);
    });

    watch(
      () => displayImagesAll.value,
      () => {
        displayImagesPerPage.value = displayImagesAll.value.slice(0, displayImagesCountPerPage);
      }
    );

    return {
      core,
      DisplayDate,
      displayImagesAll,
      selectedImageIndex,
      displayImagesPerPage,
      currentPage,
      totalPage,
      extraPurchaseDateString,
      maxSalesString,
      getDisplayImagesIndex,
      onClick,
      nextPage,
      prevPage,
      blank,
      modalStore,
      purchase,
      open,
      calculateExpirationDate,
      isExpired,
      isExtraPurchaseDate,
      purchaseDate,
      purchaseQuantity,
      totalPrice,
      maxSalesQuantityPerUser,
      product,
      isBasic,
    };
  },
});
